import * as React from "react";

export const dashboardIcon = (
  <svg height="48" viewBox="0 0 48 48" width="48">
    <path d="M0 0h48v48H0z" fill="none" />
    <path d="M6 26h16V6H6v20zm0 16h16V30H6v12zm20 0h16V22H26v20zm0-36v12h16V6H26z" />
  </svg>
);

export const userRoleIcon = (
  <svg height="32px" id="svg2" version="1.1" viewBox="0 0 32 32" width="32px">
    <g id="background">
      <rect fill="none" height="32" width="32" />
    </g>
    <g id="group_x5F_full_x5F_add">
      <path d="M22.381,7.52c0.064,0.034,0.133,0.067,0.193,0.105C23,7.856,23.48,8,24,8c1.658,0,3-1.343,3-3s-1.342-3-3-3   c-1.656,0-3,1.343-3,3C21,6.06,21.553,6.985,22.381,7.52z M8,20.001c0-2,0-10,0-10S8.005,9.551,8.238,9C6.159,9,4,9,4,9   s-2,0-2,2.001v7c0,2,2,2,2,2v10h6v-8C10,22.001,8,22.001,8,20.001z M16,8c1.657,0,3-1.343,3-3s-1.343-3-3-3s-3,1.343-3,3   S14.343,8,16,8z M8,8c0.52,0,1.001-0.144,1.427-0.376c0.059-0.036,0.125-0.068,0.188-0.103C10.446,6.988,11,6.062,11,5   c0-1.657-1.343-3-3-3S5,3.343,5,5S6.343,8,8,8z M30,17.351c0-2.261,0-6.35,0-6.35S30,9,28,9h-4.238   C23.996,9.551,24,10.001,24,10.001s0,1.888,0,4.059c-0.328-0.036-0.662-0.059-1-0.059s-0.67,0.022-1,0.059c0-1.632,0-3.059,0-3.059   S22,9,20,9c-0.5,0-8,0-8,0s-2,0-2,2.001v7c0,2,2,2,2,2v10h5.35C18.895,31.249,20.859,32,23,32c4.971,0,9-4.028,9-8.999   C32,20.859,31.248,18.896,30,17.351z M23,30c-3.865-0.008-6.994-3.135-7-6.999c0.006-3.865,3.135-6.994,7-7   c3.865,0.006,6.992,3.135,7,7C29.992,26.865,26.865,29.992,23,30z" />
      <g>
        <polygon points="28,22 24.002,22 24.002,18 22,18 22,22 18,22 18,24 22,24 22,28 24.002,28 24.002,24 28,24   " />
      </g>
    </g>
  </svg>
);

export const userIcon = (
  <svg
    enableBackground="new 0 0 32 32"
    height="32px"
    id="svg2"
    version="1.1"
    viewBox="0 0 32 32"
    width="32px"
  >
    <g id="background">
      <rect fill="none" height="32" width="32" />
    </g>
    <g id="user_x5F_half_x5F_security">
      <path d="M16,8c2.209,0,4-1.791,4-4s-1.791-4-4-4s-4,1.791-4,4S13.791,8,16,8z M24,14.059C24,12.821,24,12,24,12s0-2-2-2   c-1,0-12,0-12,0s-2,0-2,2v14c0,2,2,2,2,2v4h12v-0.059C22.329,31.978,22.661,32,23,32c4.971-0.002,8.998-4.029,9-9   C31.998,18.366,28.499,14.556,24,14.059z M23,29.883c-3.801-0.009-6.876-3.084-6.884-6.883c0.008-3.801,3.083-6.876,6.884-6.884   c3.799,0.008,6.874,3.083,6.883,6.884C29.874,26.799,26.799,29.874,23,29.883z" />
      <path d="M26.144,22c0.008-0.131,0.014-0.267,0.014-0.409c-0.006-0.678-0.088-1.492-0.556-2.259   c-0.457-0.779-1.438-1.36-2.602-1.333c-1.165-0.027-2.148,0.554-2.605,1.333c-0.47,0.767-0.552,1.581-0.556,2.259   c0,0.143,0.006,0.278,0.014,0.409H19v5h8v-5H26.144z M21.837,21.591c-0.004-0.478,0.093-0.958,0.254-1.198   c0.173-0.227,0.27-0.366,0.909-0.394c0.637,0.027,0.73,0.165,0.904,0.394c0.162,0.24,0.259,0.723,0.253,1.198   c0,0.145-0.008,0.28-0.019,0.409h-2.282C21.846,21.869,21.837,21.732,21.837,21.591z" />
    </g>
  </svg>
);


export const itemsIcon = (
  <svg
    enablebackground="new 0 0 24 24"
    height="24px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 24 24"
    width="24px"
  >
    <g>
      <path d="M20.6,5.7c0-0.2-0.1-0.3-0.2-0.4l-8.1-4.7c-0.1-0.1-0.3-0.1-0.5,0L3.6,5.3C3.5,5.4,3.4,5.5,3.4,5.7c0,0.2,0.1,0.3,0.2,0.4   l8.1,4.7c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1l8.1-4.7C20.5,6,20.6,5.8,20.6,5.7z" />
      <path d="M10.6,13.2L2.5,8.5C2.3,8.4,2.1,8.4,2,8.5C1.9,8.6,1.8,8.8,1.8,8.9v9.4c0,0.2,0.1,0.3,0.2,0.4l8.1,4.7   c0.1,0,0.2,0.1,0.2,0.1s0.2,0,0.2-0.1c0.1-0.1,0.2-0.2,0.2-0.4v-9.4C10.9,13.4,10.8,13.3,10.6,13.2z" />
      <path d="M22,8.5c-0.1-0.1-0.3-0.1-0.5,0l-8.1,4.7c-0.1,0.1-0.2,0.2-0.2,0.4V23c0,0.2,0.1,0.3,0.2,0.4c0.1,0,0.2,0.1,0.2,0.1   s0.2,0,0.2-0.1l8.1-4.7c0.1-0.1,0.2-0.2,0.2-0.4V8.9C22.2,8.8,22.1,8.6,22,8.5z" />
    </g>
  </svg>
);

export const arrowIcon = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    className="cr-sidebar__nav-item-icon"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      padding: "0px",
      transform: "rotate(-90deg)",
      transitionDuration: "0.3s",
      transitionProperty: "transform",
    }}
  >
    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
  </svg>
);

export const hamburgerIcon = (
  <>
    <svg width="24" className="nav-open" height="24" viewBox="0 0 24 24">
      <path data-name="Path 2012" d="M0 0h24v24H0z" fill="none"></path>
      <path
        data-name="Path 2013"
        d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"
        fill="#006"
      ></path>
    </svg>
    <svg
      className="nav-close"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      id="baseline-clear-24px"
    >
      <path
        data-name="Path 926"
        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        fill="#006"
        id="Path_926"
      ></path>
      <path
        data-name="Path 927"
        d="M0,0H24V24H0Z"
        fill="none"
        id="Path_927"
      ></path>
    </svg>
  </>
);

export const emailContentIcon = (
  <svg
    enableBackground="new 0 0 32 32"
    height="32px"
    id="svg2"
    version="1.1"
    viewBox="0 0 32 32"
    width="32px"
  >
    <g id="background">
      <rect fill="none" height="32" width="32" />
    </g>
    <g id="comment_x5F_edit">
      <path d="M26,8H8v2h18V8z M26,12H8v2h15h3V12z M30,17.35V2H2l0,28h2.414l6.002-6h3.643c0.498,4.498,4.309,7.998,8.941,8   c4.97-0.002,8.998-4.03,9-9C31.999,20.858,31.248,18.895,30,17.35z M14.059,22H9.584L4,27.583V4h24v11.518   C26.569,14.561,24.851,14,23,14c-2.143,0-4.106,0.751-5.652,2H8v2h7.517C14.734,19.169,14.221,20.531,14.059,22z M23,29.882   c-3.801-0.008-6.876-3.083-6.885-6.882c0.009-3.801,3.084-6.876,6.885-6.885c3.799,0.009,6.874,3.084,6.882,6.885   C29.874,26.799,26.799,29.874,23,29.882z" />
      <polygon points="22,27 19,27 19,24  " />
      <rect
        height="4.243"
        transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 56.5269 20.5858)"
        width="7.071"
        x="20.464"
        y="19.879"
      />
    </g>
  </svg>
);


export const generalIcon = (
  <svg height="48" viewBox="0 0 48 48" width="48">
    <path d="M0 0h48v48H0z" fill="none" />
    <path d="M10 4c0-1.1-.89-2-2-2s-2 .9-2 2v8H2v12h12V12h-4V4zm8 28c0 2.61 1.68 4.81 4 5.63V46h4v-8.37c2.32-.83 4-3.02 4-5.63v-4H18v4zM2 32c0 2.61 1.68 4.81 4 5.63V46h4v-8.37c2.32-.83 4-3.02 4-5.63v-4H2v4zm40-20V4c0-1.1-.89-2-2-2s-2 .9-2 2v8h-4v12h12V12h-4zM26 4c0-1.1-.89-2-2-2s-2 .9-2 2v8h-4v12h12V12h-4V4zm8 28c0 2.61 1.68 4.81 4 5.63V46h4v-8.37c2.32-.83 4-3.02 4-5.63v-4H34v4z" />
  </svg>
);



export const rightIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-circle-left" class="svg-inline--fa fa-arrow-circle-left fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"></path></svg>
);
export const crossIcon = (
  <>

    <svg
      className="nav-close"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      id="baseline-clear-24px"
    >
      <path
        data-name="Path 926"
        d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
        fill="#006"
        id="Path_926"
      ></path>
      <path
        data-name="Path 927"
        d="M0,0H24V24H0Z"
        fill="none"
        id="Path_927"
      ></path>
    </svg>
  </>
)
export const plusIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
)

export const trashIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" class="svg-inline--fa fa-trash fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg>
)
export const contactUs = (
  <svg viewBox="0 0 24 24" >    <path d="M 3 3 C 1.897 3 1 3.897 1 5 L 1 21 L 11 21 L 11 19 L 3 19 L 3 5 L 13 5 L 13 12 C 13 10.37 13.792 8.9354375 15 8.0234375 L 15 5 C 15 3.897 14.103 3 13 3 L 3 3 z M 5 7 L 5 9 L 7 9 L 7 7 L 5 7 z M 9 7 L 9 9 L 11 9 L 11 7 L 9 7 z M 18 9 C 16.318 9 15 10.317 15 12 C 15 13.683 16.318 15 18 15 C 19.682 15 21 13.683 21 12 C 21 10.317 19.682 9 18 9 z M 5 11 L 5 13 L 7 13 L 7 11 L 5 11 z M 9 11 L 9 13 L 11 13 L 11 11 L 9 11 z M 18 11 C 18.58 11 19 11.421 19 12 C 19 12.579 18.58 13 18 13 C 17.42 13 17 12.579 17 12 C 17 11.421 17.42 11 18 11 z M 5 15 L 5 17 L 7 17 L 7 15 L 5 15 z M 9 15 L 9 17 L 11 17 L 11 15 L 9 15 z M 18 16 C 15.149 16 13 17.455766 13 19.384766 L 13 21 L 23 21 L 23 19.384766 C 23 17.455766 20.851 16 18 16 z M 18 18 C 19.47 18 20.489516 18.504 20.853516 19 L 15.146484 19 C 15.509484 18.504 16.53 18 18 18 z"></path></svg>
)

export const searchIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="svg-inline--fa fa-search fa-w-16 ml-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
)
export const colorPicker = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="palette" class="svg-inline--fa fa-palette fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path></svg>
)
export const pinIcon = (
  <svg aria-hidden="true" focusable="false" data-prefix="fas" viewBox="0 0 384 512" class="svg-inline--fa fa-trash fa-w-14"><path fill="currentColor" d="M298.028 214.267L285.793 96H328c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v48c0 13.255 10.745 24 24 24h42.207L85.972 214.267C37.465 236.82 0 277.261 0 328c0 13.255 10.745 24 24 24h136v104.007c0 1.242.289 2.467.845 3.578l24 48c2.941 5.882 11.364 5.893 14.311 0l24-48a8.008 8.008 0 0 0 .845-3.578V352h136c13.255 0 24-10.745 24-24-.001-51.183-37.983-91.42-85.973-113.733z"></path></svg>
)
export const refreshIcon = (


  <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" class="svg-inline--fa fa-thumbtack fa-w-12" width="30px" height="30px"><path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z" /></svg>
)