import React from 'react';
import SidebarView from './SidebarView';

const SidebarComponent =() =>{ 
   
      
        return (
            <SidebarView />
        );
    
}

export default SidebarComponent;